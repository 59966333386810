import "./NavBar.scss";
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';


export default function Nav_bar({ menuOpen, setMenuOpen }) {
    return (
        <div className= {"navbar " + (menuOpen && "active") }>
            <div className="wrapper">
                <div className="left">
                    <a href="#intro" className= "logo">Diego Castaneda</a>
                    <a href="https://www.linkedin.com/in/castanedadiego/"className="itemContainer">
                        <LinkedInIcon className= "icon"/>
                    </a>
                    <a href="https://github.com/castanedadiego"className="itemContainer">
                        <GitHubIcon className= "icon"/>
                    </a>
                </div>
                <div className="right">
                    <div className="hamburger" onClick= {() => setMenuOpen(!menuOpen) }>
                        <span className= "line1"></span>
                        <span className= "line2"></span>
                        <span className= "line3"></span>
                    </div>
                </div>
            </div>
        </div>
    )
}
