import './App.scss';
import NavBar from './components/nav_bar/NavBar.jsx';
import Intro from './components/intro/Intro';
import { useState } from 'react';
import Portfolio from './components/portfolio/Portfolio.';
import Menu from './components/menu/Menu';
import About from './components/about/About';

function App() {

    const [menuOpen, setMenuOpen]= useState(false)

  return (
    <div className="app">
      <NavBar menuOpen={menuOpen} setMenuOpen={setMenuOpen}/>
      <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen}/>
      <div className="sections">
        <Intro/>
        <About/>
        <Portfolio/>
      </div>
    </div>
  );
}

export default App;
