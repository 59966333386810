import { useState } from "react"
import PortfolioList from "../portfolioList/PortfolioList"
import "./Portfolio.scss"

export default function Portfolio() {

    const [selected, setSelected]= useState("featured");

    const List= [
        {id: "featured",
        title: "Featured"},
        {
            id: "web",
            title:  "Web App"
        },

        {
            id: "data",
            title: "Data Science"
        }
    ]

    return (
        <div className= "portfolio" id= "portfolio">
            <h1> Portfolio</h1>
            <ul>
                {List.map( item=> (
                    <PortfolioList title= {item.title}
                    active= {selected === item.id}
                    id= {item.id}
                    setSelected= {setSelected}
                    />
                ))}
            </ul>
            <div className= "container">

                <div className= "item">
                    <a href= "https://blackjack.diegocastaneda.com">
                        <h3>BlackJack</h3>
                    </a>
                </div>
                <div className= "item">
                    <a href= "https://github.com/castanedadiego/" >
                        <h3>Twitter Clone (TBD)</h3>
                    </a>
                </div>
                <div className= "item">
                    <a href= "https://github.com/castanedadiego/" >
                        <h3>PageRank Application (TBD)</h3>
                    </a>
                </div>
            </div>
        </div>
    )
}
