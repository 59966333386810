import "./Intro.scss";
import { init } from 'ityped';
import { useEffect, useRef } from "react";


export default function Intro() {

    const textRef = useRef(null);

    useEffect( ()=> {
        init(textRef.current,
            { showCursor: false,
                backDelay: 2000,
                backSpeed: 60,
                loop: true,

            strings: ['Full-Stack Developer', 'Engineer']

             })
    }, [])

    return (
        <div className= "intro" id="intro">
            <div className="left">
                <div className="wrapper">
                        <h2> &nbsp; Hello There I'm</h2>
                        <h1>&nbsp; Diego</h1>
                        <h3> &nbsp; <span ref= {textRef}></span></h3>
                </div>
            </div>

            {/*<div className="right">
                <div className= "square1">
                    <div className= "wrapper">
                        Bachelor in Applied Computational Math from the University of Notre Dame
                    </div>

                </div>
                <div className= "square2">
                    <div className= "wrapper">

                    </div>
                </div>
                <div className= "square3">
                    <div className= "wrapper">
                        App Academy Full-Stack Bootcamp
                    </div>
                </div>

            </div> */}
        </div>
    )
}
