import "./About.scss"

export default function About() {
    return (
        <div className= "about" id="about">
            <h1>About Me</h1>
            <div className="text-wrapper">
                <p> I am a software engineer with a passion for solving hard problems and a business value-add mindset.
                    Previously, I built spreadsheets and analyzed bond investments at Travelers.
                    I studied Applied Computational Math & Economics at Notre Dame.
                </p>
                <p>
                    I was born in Dallas but have lived in over 10 different cities between 3 countries and speak Spanish and Portuguese.
                    When not coding, you can catch me playing tennis, reading, or enjoying music. I love to learn and some of my favorite topics to read and write about are:
                     probability/ game theory, the economics of the tech industry, and mindfulness.
                </p>
                <p>
                    Let's chat!
                </p>

                <p>diego.castav [@] gmail.com</p>


            </div>

        </div>
    )
}
